import React from 'react'
import Data from './Data'
import { Row, Col, Button } from 'react-bootstrap';


function Buttons({ menuItems, filterItems, setItems }) {
  return (
    <>
      <Row>
        <Col className="col-lg-12 d-flex justify-content-center">
          <Button variant="secondary" className='mx-2'
            onClick={() => setItems(Data)}>
            All
          </Button>
          {
            menuItems.map(val => (
              <Button variant="secondary" className='mx-2'
                onClick={() => filterItems(val)}>
                {val}
              </Button>
            ))
          }
        </Col>
      </Row>

    </>
  )
}

export default Buttons