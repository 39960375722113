import React from 'react';
import '../../style.css';
import Typewriter from 'typewriter-effect';
import "bootstrap/dist/css/bootstrap.min.css";

const Hero = () => {

      return (
            <>
                  <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                        <div className="hero-container text-center" data-aos="fade-in">
                              <h1>Shivi Srivastava</h1>
                              <p> <Typewriter options={{
                                    // strings: ['Freelance Web Developer', 'Full Stack Web Developer'],
                                    strings: ['WordPress Web Developer', 'Full Stack Web Developer'],
                                    autoStart: true, loop: true,
                              }} />
                              </p>


                        </div>
                  </section>
            </>
      );
};

export default Hero;
