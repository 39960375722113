import React from 'react';

import { useState } from "react";
import { Container, Row } from 'react-bootstrap';
import '../../style.css';
import Data from './Data';
import Card from './Card';
import Buttons from './Buttons';

const Projects = () => {

  const [items, setItems] = useState(Data)
  const menuItems = [...new Set(Data.map((val) => val.category))]
  const filterItems = (cat) => {
    const newItems = Data.filter((newval) => newval.category === cat)
    setItems(newItems)
  }
  return (
    <>
      <Container id="portfolio" fluid="md" className="portfolio section-bg">
        <div className="section-title pt-5 px-3 portfolio" >
          <h2 className="d-flex justify-content-center my-5">Portfolio</h2>
          <p className='fs-6 px-4'>Here is the collection of websites, I've designed using WordPress, along with the two app projects that marked significant milestones during my full stack web development course.</p>
        </div>
        {/* <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100"> */}
        <Row className='px-5 py-3 justify-content-center'>
          <Buttons
            menuItems={menuItems}
            filterItems={filterItems}
            setItems={setItems}
          />
        </Row>
        <Container fluid="md">
          <Row>
            <div className="d-flex flex-row flex-wrap justify-content-center">
              {items.map(item => {
                return (<Card item={item} key={item.id}
                />)
              })}
            </div>
          </Row>
        </Container>
        
      </Container >
    </>
  );
}

export default Projects;
