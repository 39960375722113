import { React, useState } from 'react';
import { Modal } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Link } from 'react-router-dom';
import { BiLink, BiPlus } from "react-icons/bi";

import { Button, CloseButton } from 'react-bootstrap';

function Card({ item }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        
            <div key={item.id} className="card m-2">
                <div className='card-img-top text-center'>
                    <img src={item.img} className="img-fluid img-thumbnail" alt={item.title} /><br />
                    <p class="text-uppercase">{item.title} </p><br />
                </div>

                <div className="portfolio-links">
                    <Button variant="primary" size="sm" onClick={handleOpen}>
                        <BiPlus />
                    </Button>

                    <Modal open={open}>
                        <Modal.Title className='fs-8 bg-primary bg-gradient p-3 text-white lh-base' data-bs-theme="dark">{item.title}
                            <CloseButton className="float-end text-white" onClick={handleClose} />

                        </Modal.Title>
                        <hr />
                        <Modal.Body><p className='fs-6'> {item.desc}
                            <br /><br /><em>{item.tech}</em></p> </Modal.Body>
                        <Modal.Footer>
                            <Link to={item.link} target="_blank" rel="noopener noreferrer" title={item.title}> {item.linkTitle} </Link>
                        </Modal.Footer>
                    </Modal>
                    <Button variant="primary" size="sm" href={item.link} target="_blank" rel="noopener noreferrer" title={item.title}>
                        <BiLink />
                    </Button>
                </div>

            </div>

    )
}

export default Card