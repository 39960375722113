// Contact.js 
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
// import { BiEnvelope, BiLocationPlus, BiPhone } from "react-icons/bi";
import Swal from 'sweetalert2';
import '../../style.css';

const Contact = () => {

  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [subject,setSubject] = useState('');
  const [message,setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      name: name,
      email: email,
      subject: subject,
      message: message
    }

    // fetch("http://localhost/shivisrivastava/backend/sendmail.php",{
    fetch("/backend/sendmail.php", {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json"
      }
    }).then((status)=>{
      
      if(status){    

          // Clearing form
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");

          Swal.fire({
            title: "Thank you!",
            text: "Your message has been sent.",
            icon: "success"
          })


      }else{
        alert("Failed to sent mail");
      }
    })



  };


  //     $.ajax({
  //       type: 'POST',
  //       url: '/php/sendmail.php',
  //       data: {
  //           name: name,
  //           email: email,
  //           subject: subject,
  //           message: message
  //   }
  // });

  return (
    <>
      <Container id="contact" fluid className="md d-flex align-items-center justify-content-center">
        <div className="section-title pt-5 px-3">
          <h2 className="d-flex justify-content-center my-5">Contact</h2>
          <p className='fs-6 px-4'>Please feel free to contact me.</p>

        </div>
      </Container>
      <Container className='contact d-flex align-items-center justify-content-center'>
        {/* <div className="row" data-aos="fade-in"> */}
        <Row className='w-75'>
          {/* <div className="col-lg-4 d-flex align-items-stretch">
            <div className="info"> */}
          {/* <Col className="info ms-5">
            <div className="address my-3">
              <i><h3><BiLocationPlus /></h3></i>
              <h4>Location:</h4>
              <p>Wembley Park, London, HA9 0FX</p>
            </div>

            <div className="email my-3">
              <i><h3><BiEnvelope /></h3></i>
              <h4>Email:</h4>
              <p>virgoshivi@gmail.com</p>
            </div>

            <div className="phone my-3">
              <i><h3><BiPhone /></h3></i>
              <h4>Call:</h4>
              <p>+44 7721663774</p>
            </div> */}

            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe> */}
            {/* </div>

          </div> */}
          {/* </Col> */}
          <Col>
            {/* <div className="col-lg-8 mt-5 mt-lg-0 d-flex align-items-stretch"> */}
            <form method="POST" action="/sendmail.php" className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor={'name'}>Your Name</label>
                  <input type="text" name="name" className="form-control" value={name} id="name" 
                  onChange={ (e)=> setName(e.target.value)} required />

                </div>
                <div className="form-group col-md-6">
                  <label htmlFor={'email'}>Your Email</label>
                  <input type="email" className="form-control" name="email" value={email} id="email" onChange={(e)=> setEmail(e.target.value)} required />

                </div>
              </div>
              <div className="form-group">
                <label htmlFor={'subject'}>Subject</label>
                <input type="text" className="form-control" name="subject" value={subject} id="subject" onChange={(e)=> setSubject(e.target.value)} required />

              </div>
              <div className="form-group">
                <label htmlFor={'message'}>Message</label>
                <textarea className="form-control" name="message" rows="10" value={message} onChange={(e)=> setMessage(e.target.value)} required></textarea>
              </div>
            
              <div className="text-center">
                <Button onClick={handleSubmit} name="submit">Send Message</Button>
              </div>
              <div>

              </div>
            </form>
            {/* </div> */}
          </Col>
          {/* </div> */}
        </Row>

      </Container>


    </>
  );
};

export default Contact;
