//Header.js 
import { React, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Button } from 'rsuite';
import { BiHome, BiUser, BiFileBlank, BiBookContent, BiServer, BiEnvelope, BiLogoFacebookCircle, BiLogoLinkedin, BiMenu, BiSolidXCircle } from "react-icons/bi";
import Container from 'react-bootstrap/Container';
import '../../style.css';
import profileimg from '../../assets/img/Shivi.png';
import { Link } from 'react-router-dom';


const Header = () => {
  const [toggle, setToggle] = useState(false);

  const toggleHandler = () => {
    setToggle(!toggle)
  }

  return (
    <>

      <Navbar.Toggle aria-controls="header" className="text-light">
        <Button id="mobile" onClick={toggleHandler}>
          { toggle === false ? <BiMenu className='mobile-nav-toggle d-xl-none' /> : <BiSolidXCircle className='mobile-nav-toggle d-xl-none' /> }
          </Button>
        {/* <BiSolidXCircle className='mobile-nav-toggle d-xl-none' /> */}
      </Navbar.Toggle>

      <header id="header" className={`d-xl-block ${toggle === true ? 'visible' : ''}`} >
        <div className="d-flex flex-column">
          <div className="profile justify-content-center">
            <img src={profileimg} className="img-fluid rounded-circle" alt="" />

            <h1 className="text-light"><a href="index.html">Shivi Srivastava</a></h1>
            <div className="social-links mt-3 text-center">
              <Link to ="https://www.facebook.com/shivi.srivastava" target="_blank" rel="noopener noreferrer"><BiLogoFacebookCircle /></Link>
              <Link to ="https://www.linkedin.com/in/shivi-srivastava-6a142a17" target="_blank" rel="noopener noreferrer"><BiLogoLinkedin /></Link>
            </div>
          </div>
          <Container className='py-4 d-flex flex-column justify-content-center align-items-center'>
            <Navbar>
              <Container>
                <Nav.Link href="#hero" className="text-light text-decoration-none nav-link scrollto active"><h5><BiHome className="mx-2" />Home</h5></Nav.Link>
              </Container>
            </Navbar>
            <Navbar>
              <Container>
                <Nav.Link href="#about" className="text-light text-decoration-none nav-link scrollto active"><h5><BiUser className="mx-2" />About</h5></Nav.Link>
              </Container>
            </Navbar>
            <Navbar>
              <Container>
                <Nav.Link href="#skills" className="text-light text-decoration-none nav-link scrollto active"><h5><BiServer className="mx-2" />Skills</h5></Nav.Link>
              </Container>
            </Navbar>
            <Navbar>
              <Container>
                <Nav.Link href="#curriculum_vitae" className="text-light text-decoration-none nav-link scrollto active"><h5><BiFileBlank className="mx-2" />Curriculum Vitae</h5></Nav.Link>
              </Container>
            </Navbar>
            <Navbar>
              <Container>
                <Nav.Link href="#portfolio" className="text-light text-decoration-none nav-link scrollto active"><h5><BiBookContent className="mx-2" />Portfolio</h5></Nav.Link>
              </Container>
            </Navbar>
            {/* <Navbar>
              <Container>
                <Nav.Link href="#testimonials" className="text-light text-decoration-none nav-link scrollto active"><h5><BiBookContent className="mx-2" />Testimonials</h5></Nav.Link>
              </Container>
            </Navbar> */}
            <Navbar>
              <Container>
                <Nav.Link href="#contact" className="text-light text-decoration-none nav-link scrollto active"><h5><BiEnvelope className="mx-2" />Contact</h5></Nav.Link>
              </Container>
            </Navbar>

          </Container>
          <footer id="footer">
            <Container>
              <div className="copyright">
                &copy; Copyright <strong><span>iPortfolio</span></strong>
              </div>
              <div className="credits">
                {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/iportfolio-bootstrap-portfolio-websites-template/ --> */}
                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
              </div>
            </Container>
          </footer>
        </div>
      </header>

    </>

  );
};

export default Header;
