//About.js 
import React from 'react';
import Container from 'react-bootstrap/Container';
import '../../style.css'; 

const About = () => { 
	return (
		<>
    <Container id="about" fluid="md">
        <div className="section-title pt-5 px-3">
          <h2 className="d-flex justify-content-center my-5">About</h2>
          <p className='fs-6 px-4'>Hi, I am Shivi, a self-starter, dynamic individual, with a solution-oriented attitude with a strong passion for learning and self-improvement. I thrive on challenges and continuously seek opportunities to enhance my skills and capabilities.<br /> <br />  </p>
          <p className='fs-6 px-4'>Starting my website design journey in Switzerland, I developed a number of websites which fueled my ambition to become a full fledged website developer. Recently, I successfully completed a comprehensive year-long Full Stack Developer course offered by IT Career Switch and Codecademy, where I acquired invaluable expertise in various facets of web development. Throughout this journey, I've embraced the opportunity to expand my knowledge and refine my abilities, always pushing myself to reach new heights.</p>
          {/* <p className='fs-6 px-4'>Starting my website design journey in Switzerland as a freelance, I developed a number of websites which fueled my ambition to become a full fledged website developer. Recently, I successfully completed a comprehensive year-long Full Stack Developer course offered by IT Career Switch and Codecademy, where I acquired invaluable expertise in various facets of web development. Throughout this journey, I've embraced the opportunity to expand my knowledge and refine my abilities, always pushing myself to reach new heights.</p> */}
        </div>      
    </Container>
	</>
	); 
}; 

export default About;
