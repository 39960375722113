//App.js 
import React, {useState} from 'react'; 
import { BrowserRouter as Router } from 'react-router-dom'; 
import Header from './components/Header/Header'; 
import Hero from './components/Hero/Hero';
import About from './components/About/About'; 
import Skills from './components/Skills/Skills';
import CV from './components/Curriculum_vitae/Curriculum_vitae';
import Projects from './components/Projects/Projects'; 
// import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/Contact/Contact';


import './style.css'; 

function App() { 
	const [loading, setLoading] = useState(true);
	const spinner = document.getElementById('spinner');
	if(spinner) {
		setTimeout(() => {
			spinner.style.display = "none"
			setLoading(false);
		},2000)
	}
return ( 
	!loading && (
		<Router> 
	<div id="main"> 

		<Header /> 
		<Hero />
		<About />
		<Skills />
		<CV />
		<Projects />
		{/* <Testimonials /> */}
		<Contact />
		
	</div> 
	</Router> 
	)
	
); 
} 

export default App; 

