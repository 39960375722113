import portfolio1 from '../../assets/img/portfolio/Gazetteer.png';
import portfolio2 from '../../assets/img/portfolio/CompanyDirectory.png';
import portfolio3 from '../../assets/img/portfolio/Mylondonproperty.png';
import portfolio4 from '../../assets/img/portfolio/Rekhasethi.png';
import portfolio5 from '../../assets/img/portfolio/Vatayan.png';
import portfolio6 from '../../assets/img/portfolio/Frenchlanguageacademy.png';
import portfolio7 from '../../assets/img/portfolio/Artutkal.png';
import portfolio8 from '../../assets/img/portfolio/Smileyworld.png';
import portfolio9 from '../../assets/img/portfolio/Imdcare.png';
import portfolio10 from '../../assets/img/portfolio/Darshanam.png';
import portfolio11 from '../../assets/img/portfolio/Nilgiri.png';
import portfolio12 from '../../assets/img/portfolio/Callnpick.png';
import portfolio13 from '../../assets/img/portfolio/Allezgotaxi.png';
import portfolio14 from '../../assets/img/portfolio/Diannegoette.png';
import portfolio15 from '../../assets/img/portfolio/Divyamathur.png';
import portfolio16 from '../../assets/img/portfolio/Indianzayeka.png';
import portfolio17 from '../../assets/img/portfolio/Chemistrywithdb.png';



const Data = [
    {
        id: 1,
        title: 'Gazetteer',
        category: 'Web Application',
        desc: 'Designed as a "mobile-first" web application, full-screen, the Gazetteer is an interactive geolocation map that works equally well across desktops and tablets. Gazetteer provides informations on a selected country about demographic, climatic, geographical, latest news, and other data.',
        img: portfolio1,
        tech: 'HTML5, Bootstrap, CSS3, PHP, JavaScript, jQuery, RESTful APIs',
        link: '/project1/index.html',
        linkTitle: 'Project URL',
    
    },
    {
        id: 2,
        title: 'Company Directory',
        category: 'Web Application',
        desc: 'A mobile-first application designed for efficient management of company personnel. With a responsive design, it allows seamless creation, retrieval, and modification of staff information, providing a professional and user-friendly experience on both mobile and desktop platforms.',
        img: portfolio2,
        tech: 'HTML5, Bootstrap, CSS3, PHP, MySQL/PHPMyAdmin, SQL, JavaScript, jQuery',
        link: '/project2/index.html',
        linkTitle: 'Project URL',
    
    },
    {
        id: 3,
        title: 'My London Property Dream',
        category: 'WordPress Website',
        desc: 'Designed and developed a website for an entrepreneur launching her property counseling business in London, including a custom logo to establish her brand identity.',
        img: portfolio3,
        tech: 'used WP Bakery Page Builder',
        link: 'https://www.mylondonpropertydream.com/',
        linkTitle: 'www.mylondonpropertydream.com',
    
    },
    {
        id: 4,
        title: 'Rekha Sethi',
        category: 'WordPress Website',
        desc: 'An Indian author wished to showcase all her works on a single website in Hindi and English. I delivered comprehensive development services for the website, including creating the logo.',
        img: portfolio4,
        tech: 'used Block Editor (Astra Theme), WPML',
        link: 'https://rekhasethi.com/',
        linkTitle: 'www.rekhasethi.com',
    
    },
    {
        id: 5,
        title: 'Vatayan Europe',
        category: 'WordPress Website',
        desc: 'An organization dedicated to promoting Indo-British dialogue in London needed a bilingual website to showcase their programs. I designed and developed a responsive website for them.',
        img: portfolio5,
        tech: 'used Block Editor (Astra Theme), WPML',
        link: 'https://www.vatayaneurope.com/',
        linkTitle: 'www.vatayaneurope.com',
    
    },
    {
        id: 6,
        title: 'French Language Academy ',
        category: 'WordPress Website',
        desc: 'A passionate French tutor in Lausanne wanted to start her own institute. I supported her by providing comprehensive design and development services for her multilingual website, available in three languages, and also designed the logo.',
        img: portfolio6,
        tech: 'used Block Editor (Astra Theme), WPML',
        link: 'https://www.frenchlanguageacademy.ch/',
        linkTitle: 'www.frenchlanguageacademy.ch',
    
    },
    {
        id: 7,
        title: 'Art Utkal',
        category: 'WordPress Website',
        desc: 'A website for an artist entrepreneur looking to display her portfolio as well as drive commerce for her artwork. Also responsible for logo design.',
        img: portfolio7,
        tech: 'used Block Editor (Astra Theme), WPML, WooCommerce',
        link: 'https://www.artutkal.ch/',
        linkTitle: 'www.artutkal.ch',
    
    },
    {
        id: 8,
        title: 'Smiley World ',
        category: 'WordPress Website',
        desc: 'A private bilingual day care in Lausanne, Switzerland was looking for someone to revamp their brand image. I helped them by bringing a fresh design through a modern website combining features including online registration.',
        img: portfolio8,
        tech: 'used WP Bakery Page Builder, WPML',
        link: 'https://www.garderie-smileyworld.ch/',
        linkTitle: 'www.garderie-smileyworld.ch',
    
    },
    {
        id: 9,
        title: 'IMDCare',
        category: 'WordPress Website',
        desc: 'A private bilingual pre-school in looking for a website to launch their preschool and they hired me to develop ground-up their digital property.',
        img: portfolio9,
        tech: 'used WP Bakery Page Builder, WPML',
        link: 'https://www.imdcare.ch/',
        linkTitle: 'www.imdcare.ch',
    
    },
    {
        id: 10,
        title: 'Institut Darshanam',
        category: 'WordPress Website',
        desc: 'An Ayurvedic Institut in the French part of Switzerland.  I developed a bilingual website which allows his French and English clients to book an online appointment and get confirmation instantly. Designed the brouchure as well.',
        img: portfolio10,
        tech: 'used WP Bakery Page Builder, WPML, Booked',
        link: 'https://www.darshanam.ch/',
        linkTitle: 'www.darshanam.ch',
    
    },
    {
        id: 11,
        title: 'Niligiri',
        category: 'WordPress Website',
        desc: 'An extension of Institut Darshanam, promoting ayurvedic treatments in Switzerland. I helped them with their web presence through end to end design and development of their website. Contributed to the logo design process.',
        img: portfolio11,
        tech: 'used WP Bakery Page Builder, WPML, Booked',
        link: 'https://www.nilgiri.ch/',
        linkTitle: 'www.nilgiri.ch',
    
    },
    {
        id: 12,
        title: 'Call & Pick',
        category: 'WordPress Website',
        desc: 'A local e-delivery business in Lausanne needed a website for booking orders and product display. I provided end-to-end development of the website alongwith the logo.',
        img: portfolio12,
        tech: 'used WP Bakery Page Builder, WPML, WooCommerce',
        link: 'https://www.callnpick.com/',
        linkTitle: 'www.callnpick.com',
    
    },
    {
        id: 13,
        title: 'Allez Go Taxi Evian',
        category: 'WordPress Website',
        desc: 'A taxi company in Evian, providing reliable taxi services to customers. I designed and developed a responsive website where customers can easily book a taxi online.',
        img: portfolio13,
        tech: 'used Elementor, WPML',
        link: 'https://www.allezgotaxievian.com/',
        linkTitle: 'www.allezgotaxievian.com',
    
    },
    {
        id: 14,
        title: 'Dianne Goette',
        category: 'Wix',
        desc: 'An entrepreneur launching her counselling business in Switzerland.  Maintained and updated the existing website designed in WIX.',
        img: portfolio14,
        tech: 'used Wix',
        link: 'https://www.diannegoette.com/',
        linkTitle: 'www.diannegoette.com',
    
    },
    {
        id: 15,
        title: 'Divya Mathur',
        category: 'WordPress Website',
        desc: 'Another Indian author wanted to create a personal bilingual website to showcase her lifetime works and achievements. I assisted her by providing end-to-end design and development services, and contributed to the logo design process.',
        img: portfolio15,
        tech: 'used Block Editor (Astra Theme), WPML',
        link: 'https://www.divyamathur.com/',
        linkTitle: 'www.divyamathur.com',
    
    },
    {
        id: 16,
        title: 'Indian Zayeka',
        category: 'Wix',
        desc: 'An Indian restaurant in Lausanne wanted to redesign their WIX website. I revamped the site and added an online table reservation feature for customers.',
        img: portfolio16,
        tech: 'used Wix',
        link: 'https://www.indianzayeka.ch/',
        linkTitle: 'www.indianzayeka.ch',
    
    },
    {
        id: 17,
        title: 'Chemistry with DB',
        category: 'Wix',
        desc: 'Designed and developed a dedicated online platform for an experienced chemistry tutor who has been inspiring and educating students in London for over 15 years. Contributed to the logo design process.',
        img: portfolio17,
        tech: 'used Wix',
        link: 'https://www.chemistrywithdb.com/',
        linkTitle: 'www.chemistrywithdb.com',
    
    },

    
    
];

export default Data;