// Skills.js 
import React from 'react'; 
import { Container, Row, Col } from 'react-bootstrap';
import { BiLogoAdobe, BiLogoBootstrap, BiLogoCss3, BiLogoHtml5, BiLogoJavascript, BiLogoJquery, BiLogoNodejs, BiLogoPhp, BiLogoPostgresql, BiLogoReact, BiLogoWordpress } from "react-icons/bi";	
import '../../style.css'; 

const Skills = () => { 
	return ( 
		<>
      <Container id="skills" fluid="md">
        <div className="section-title pt-5 px-3">
          <h2 className="d-flex justify-content-center my-4">Skills</h2>
        </div>
      </Container>
      <Container fluid="md">
                <Row>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                  {/* <div className="col" data-aos="fade-up"> */}
                    <Col>  <BiLogoHtml5 className="w-50 h-auto" /> HTML </Col>
                    <Col>  <BiLogoJavascript className="w-50 h-auto" /> JavaScript</Col>
                    <Col>  <BiLogoReact className="w-50 h-auto" /> ReactJs </Col>
                    <Col>  <BiLogoCss3 className="w-50 h-auto" /> CSS </Col>
                    <Col>  <BiLogoWordpress className="w-50 h-auto" /> Wordpress</Col>
                    <Col>  <BiLogoNodejs className="w-50 h-auto" /> NodeJs</Col>
                   </div> 
                 </Row>   
                  {/* </div> */}
                  <Row className="pt-5 ps-5">
                  {/* <div className="col" data-aos="fade-up" data-aos-delay="100"> */}
                    <Col >  <BiLogoBootstrap className="w-50 h-auto" /> Bootstrap <br /><br /></Col>
                    <Col>  <BiLogoPhp className="w-50 h-auto" /> PHP<br /><br /></Col>
                    <Col>  <BiLogoPostgresql className="w-50 h-auto" /> MySQL</Col>
                    <Col>  <BiLogoJquery className="w-50 h-auto" /> jQuery <br /><br /></Col>
                    <Col>  <BiLogoCss3 className="w-50 h-auto" /> Python<br /><br /></Col>
                    <Col>  <BiLogoAdobe className="w-50 h-auto" /> Adobe Suite</Col>
                {/* </div> */}
                  </Row>
                 
              {/* </div> */}
          {/* </div> */}
      </Container>
		</>
	); 
}; 

export default Skills;
