// Resume.js 
import React from 'react';
import '../../style.css';
import { Container, Button, Row } from 'react-bootstrap';
import cvDownload from '../../assets/Shivi Srivastava CV.pdf';

const Resume = () => {
	return (
		<>
		 
         
            
			<Container id="curriculum_vitae" className="resume" fluid="md">
				<Row className='ms-5 justify-content-center'>
					<div className="section-title pt-5 px-3 ">
						<h2 className="mt-5 d-flex justify-content-center">Curriculum Vitae</h2>
					</div>
					<div className="d-flex align-items-center justify-content-center mb-5">
						<Button href={cvDownload} className="justify-content-center text-decoration-none" download>Download CV</Button>
					</div>

				</Row>
				<Row className='ms-5 d-flex justify-content-center'>
				{/* <div className="d-flex flex-row flex-wrap"> */}
					<div className="col-lg-6 d-flex flex-row flex-wrap">
						{/* <div data-aos="fade-up" data-aos-delay="100"> */}
							<h4 className="resume-title">PROFESSIONAL EXPERIENCE</h4>
							<div className="resume-item">
								<h4 className='lh-sm'>Full Stack Developer Traineeship (IT Career Switch)</h4>
								<h5>2023 - Present</h5>
								{/* <p><em>Experion, New York, NY </em></p> */}
								<ul>
									<li><p className='fs-6'>Learnt the fundamentals of several languages including HTML, CSS, Javascript, Python, PHP, Bootstrap and SQL.</p></li>
									<li><p className='fs-6'>Used my skills to create a portfolio website.</p></li>
									<li><p className='fs-6'>I implemented my knowledge by creating and working on 2 projects; Gazetteer and Company Directory.</p></li>
								</ul>
							</div>
							<div className="resume-item">
								<h4 className='lh-sm'>Web Design / Development (Using WORDPRESS / WIX)</h4>
								<h5>2016 - Present</h5>
								{/* <p><em>Stepping Stone Advertising, New York, NY</em></p> */}
								<ul>
									<li><p className='fs-6'>Designed and developed 16 websites using WordPress or Wix.</p></li>
									<li><p className='fs-6'>Created custom logos for several clients.</p></li>
									<li><p className='fs-6'>Provided search engine optimization (SEO) services for a number of websites to improve the online visibility of their business.</p></li>
								</ul>
							</div>
						{/* </div> */}
						{/* </div> */}
					</div>
					{/* <Col className='col-lg-6 flex-column'> */}
					<div className="col-lg-6 d-flex flex-column flex-wrap">
						<h4 className="resume-title">EDUCATION</h4>
						<div className="resume-item">
							<h4>Master of Computer Applications</h4>
							{/* <h5>2004 - 2007</h5> */}
							<p><em>G.G.S. Indraprastha University, Delhi, India</em></p>
						</div>
						<div className="resume-item">
							<h4 className='lh-sm'>Certificate course (Codecademy) in  Full-Stack Engineer Career Path, jQuery, Python 3, Bootstrap, PHP</h4>
							{/* <h5>2002 - 2004</h5> */}
							{/* <p><em>Delhi University, Delhi, India</em></p> */}
						</div>
						<div className="resume-item">
							<h4>Arena Multimedia Specialist Program</h4>
							{/* <h5>2002 - 2004</h5> */}
							<p><em>Arena Multimedia, Delhi, India</em></p>
						</div>
						<div className="resume-item">
							<h4>Master of Arts (Philosophy)</h4>
							{/* <h5>2002 - 2004</h5> */}
							<p><em>Delhi University, Delhi, India</em></p>
						</div>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default Resume;

